import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $getRoot } from "lexical";
import { $generateNodesFromDOM, $generateHtmlFromNodes } from "@lexical/html";

export default function UpdatePlugin({ value }) {
  const [editor] = useLexicalComposerContext();

  function update(value) {
    editor.update(() => {
      // 📌 Vérifier la valeur actuelle de l'éditeur
      let currentHtml = "";
      editor.getEditorState().read(() => {
        currentHtml = $generateHtmlFromNodes(editor);
      });

      // 📌 Si la valeur est identique, ne pas mettre à jour
      if (currentHtml.trim() === value.trim()) return;

      // 📌 Effacer et insérer le nouveau contenu HTML
      const root = $getRoot();
      root.clear();

      const parser = new DOMParser();
      const dom = parser.parseFromString(value, "text/html");
      const nodes = $generateNodesFromDOM(editor, dom);

      // 📌 Sauvegarder la position du scroll
      const scrollPosition = window.scrollY;

      nodes.forEach((node) => {
        try {
          $getRoot().append(node);
        } catch (e) {
          const paragraphNode = $createParagraphNode();
          paragraphNode.append(node);
          $getRoot().append(paragraphNode);
        }
      });

      // 📌 Restaurer le scroll après mise à jour
      setTimeout(() => {
        window.scrollTo(0, scrollPosition);
      }, 100);
    });
  }

  useEffect(() => {
    if (value && editor) {
      update(value);
    }
  }, [value, editor]);

  return null;
}
