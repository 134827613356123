import React from "react";
import { Row } from "react-flexbox-grid";
import { ButtonIcon } from "ui-kit-ck-consultant";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IdentificationPartiesUser from "../../../../components/folder/administrative/IdentificationPartiesUser";
import AddIdentificationPartiesUser from "../../../../components/folder/administrative/AddIdentificationPartiesUser";

import CustomCard from "../../../../components/folder/general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class IdentificationParties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalIdentificationPartiesUser: false,
      idxModalIdentificationPartiesUser: null,
    };
    this.displayName = "IdentificationParties";
  }

  static contextType = AuthContext;

  setData = (data, callback = () => {}) => {
    this.props.setData("dataIdentificationParties", data, callback);
  };

  getParties = (party, index) => {
    let data = [];

    Object.keys(this.props.data).forEach((key) => {
      if (
        party === (this.props.data[key].party || "Aucune") &&
        ((party !== "Adverse" && party !== "Tiers") ||
          (this.props.data[key].idParty || 1) === index)
      ) {
        data.push(
          <IdentificationPartiesUser
            key={key}
            idx={key}
            {...this.props.data[key]}
            name={`${this.props.data[key].kind} ${this.props.data[key].name}`}
            data={this.props.data}
            setData={this.setData}
            dataParties={this.props.dataParties}
          />
        );
      }
    });

    return data;
  };

  render() {
    return (
      <CustomCard
        title="Identification des parties"
        action={
          this.props.dataParties ? (
            <ButtonIcon
              className="black p-0 mr-10"
              small
              onClick={() =>
                window.confirmCustom(
                  "Êtes-vous sûr de vouloir rénitialiser les parties masqués",
                  (result) => {
                    if (result) {
                      let tmpData = this.props.dataParties;

                      tmpData = [];

                      this.context.updateDataReport("partiesReport", tmpData);
                    }
                  }
                )
              }
              info="Rénitialiser les parties masqués"
            >
              <FontAwesomeIcon icon={faRotate} />
            </ButtonIcon>
          ) : null
        }
        name={this.displayName}
      >
        <h2>Lésé</h2>
        <Row>{this.getParties("Lésé")}</Row>
        {(() => {
          let items = [];
          const parties = this.getParties("Assuré");
          if (parties.length) {
            items.push(<h2>Assuré</h2>);
            items.push(<Row>{parties}</Row>);
          }
          return items;
        })()}
        {(() => {
          let items = [];
          const parties = this.getParties("Assureur");
          if (parties.length) {
            items.push(<h2>Assureur</h2>);
            items.push(<Row>{parties}</Row>);
          }
          return items;
        })()}
        {(() => {
          let items = [];
          for (let index = 1; index < 6; index++) {
            const parties = this.getParties("Tiers", index);
            if (parties.length) {
              items.push(<h2 key={`${index}_title`}>Tiers {index}</h2>);
            }
            items.push(<Row key={`${index}_body`}>{parties}</Row>);
          }
          return items;
        })()}
        {(() => {
          let items = [];
          const parties = this.getParties("Réparateur");
          if (parties.length) {
            items.push(<h2>Réparateur</h2>);
            items.push(<Row>{parties}</Row>);
          }
          return items;
        })()}
        {(() => {
          let items = [];
          const parties = this.getParties("Dépositaire");
          if (parties.length) {
            items.push(<h2>Dépositaire</h2>);
            items.push(<Row>{parties}</Row>);
          }
          return items;
        })()}
        {(() => {
          let items = [];
          const parties = this.getParties("Centre de contrôle technique");
          if (parties.length) {
            items.push(<h2>Centre de contrôle technique</h2>);
            items.push(<Row>{parties}</Row>);
          }
          return items;
        })()}
        {(() => {
          let items = [];
          for (let index = 1; index < 6; index++) {
            const parties = this.getParties("Adverse", index);
            if (parties.length) {
              items.push(<h2 key={`${index}_title`}>Adverse {index}</h2>);
            }
            items.push(<Row key={`${index}_body`}>{parties}</Row>);
          }
          return items;
        })()}
        <h2>Aucune</h2>
        <Row>{this.getParties("Aucune")}</Row>
        <AddIdentificationPartiesUser
          data={this.props.data}
          setData={this.setData}
        />
      </CustomCard>
    );
  }
}
