import { openDB } from "idb";
import moment from "moment";

async function connection() {
  return await openDB("RedaxionDB", 14, {
    upgrade(db) {
      try {
        db.deleteObjectStore("folders");
      } catch (error) {}
      try {
        db.deleteObjectStore("types");
      } catch (error) {}
      try {
        db.deleteObjectStore("states");
      } catch (error) {}
      try {
        db.deleteObjectStore("agencies");
      } catch (error) {}
      try {
        db.deleteObjectStore("companies");
      } catch (error) {}
      try {
        db.deleteObjectStore("regions");
      } catch (error) {}
      try {
        db.deleteObjectStore("appointmentTypes");
      } catch (error) {}
      try {
        db.deleteObjectStore("users");
      } catch (error) {}
      try {
        db.deleteObjectStore("homeFolders");
      } catch (error) {}
      try {
        db.deleteObjectStore("homeCountFolders");
      } catch (error) {}
      try {
        db.deleteObjectStore("actions");
      } catch (error) {}
      try {
        db.deleteObjectStore("calls");
      } catch (error) {}
      try {
        db.deleteObjectStore("appointments");
      } catch (error) {}
      try {
        db.deleteObjectStore("technicalAdvices");
      } catch (error) {}
      try {
        db.deleteObjectStore("actionsTmp");
      } catch (error) {}
      try {
        db.deleteObjectStore("files");
      } catch (error) {}

      const store = db.createObjectStore("folders", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      store.createIndex("id", "id", { unique: true });
      store.createIndex("token", "token", { unique: true });

      db.createObjectStore("types", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      db.createObjectStore("states", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      db.createObjectStore("agencies", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      db.createObjectStore("companies", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      db.createObjectStore("regions", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      db.createObjectStore("appointmentTypes", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      db.createObjectStore("users", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      db.createObjectStore("homeFolders", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      db.createObjectStore("homeCountFolders", {
        keyPath: "idLocal",
        autoIncrement: true,
      });

      const storeActions = db.createObjectStore("actions", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      const storeCalls = db.createObjectStore("calls", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      const storeAppointments = db.createObjectStore("appointments", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      const storeTechnicalAdvices = db.createObjectStore("technicalAdvices", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      const storeActionsTmp = db.createObjectStore("actionsTmp", {
        keyPath: "idLocal",
        autoIncrement: true,
      });
      const storeFiles = db.createObjectStore("files", {
        keyPath: "idLocal",
        autoIncrement: true,
      });

      storeActions.createIndex("folderId", "folderId");
      storeCalls.createIndex("folderId", "folderId");
      storeAppointments.createIndex("folderId", "folderId");
      storeTechnicalAdvices.createIndex("folderId", "folderId");
      storeActionsTmp.createIndex("folderId", "folderId");
      storeFiles.createIndex("folderId", "folderId");
    },
  });
}

/* START - APP + HOME REQUEST */

async function getAll(table, callback) {
  const db = await connection();
  const data = await db.getAll(table);
  db.close();

  callback({ success: !!data.length, data });
}

async function insertAll(data, table, callback) {
  const db = await connection();

  const store = await db.transaction(table, "readwrite").objectStore(table);
  await store.clear();

  const tx = db.transaction(table, "readwrite");

  if (data) {
    data.forEach((element) => {
      tx.objectStore(table).add(element);
    });
  }

  tx.oncomplete = () => {
    callback({ success: true });
  };
}

/* END - APP + HOME REQUEST */

/* START - FOLDER REQUEST */

async function getFolders(callback) {
  const db = await connection();
  const data = await db.getAll("folders");
  db.close();

  callback({ data });
}

async function getFolder(token, callback) {
  const db = await connection();
  const store = await db
    .transaction("folders", "readonly")
    .objectStore("folders");
  const myIndex = store.index("token");
  const data = await myIndex.get(token);
  db.close();

  if (data && data.id) {
    callback({ success: true, data });
  } else {
    callback({ success: false });
  }
}

async function insertFolder(data, callback) {
  const db = await connection();

  const store = await db
    .transaction("folders", "readwrite")
    .objectStore("folders");
  const myIndex = store.index("token");
  const dataLocal = await myIndex.get(data.token);
  if (dataLocal && dataLocal.idLocal) {
    await store.delete(dataLocal.idLocal);
  }

  await db.add("folders", data);
  db.close();

  callback({ success: true });
}

async function deleteFolder(id, callback) {
  const db = await connection();
  const store = await db
    .transaction("folders", "readwrite")
    .objectStore("folders");
  await store.delete(id);

  callback({ success: true });
}

async function updateFolder(folderId, name, data, other, dateUpdate, callback) {
  const db = await connection();
  const store = db.transaction("folders", "readwrite").objectStore("folders");

  const myIndex = store.index("id");
  const dataLocal = await myIndex.get(folderId);

  dataLocal.dateUpdate = dateUpdate;
  dataLocal[name] = data;
  dataLocal.dataOther = other;
  store.put(dataLocal);

  callback({ success: true });
}

async function getAllFolder(folderId, table, callback) {
  const db = await connection();

  let data = await db.getAll(table);
  data = data.filter((element) => element.folderId === folderId);
  db.close();

  if (data && data.length) {
    callback({ success: true, data });
  } else {
    callback({ success: false });
  }
}

async function insertAllFolder(folderId, data, table, callback) {
  const db = await connection();

  // update data
  data.forEach((element) => {
    element.folderId = folderId;
  });

  // clean data
  let oldData = await db.getAll(table);
  if (oldData) {
    oldData = oldData.filter((element) => element.folderId !== folderId);
    oldData.forEach((element) => {
      delete element.idLocal;
    });
  } else {
    oldData = [];
  }
  const store = await db.transaction(table, "readwrite").objectStore(table);
  await store.clear();

  // add data
  const tx = db.transaction(table, "readwrite");
  [...oldData, ...data].forEach((element) => {
    tx.objectStore(table).add(element);
  });

  tx.oncomplete = () => {
    callback({ success: true });
  };
}

/* END - FOLDER REQUEST */

/* START - ACTIONS TMP */

async function insertActionsTmp(folderId, action, keyData, callback) {
  const db = await connection();

  await db.add("actionsTmp", {
    folderId,
    action,
    keyData,
    date: moment().format("YYYY-MM-DD HH:mm:ss"),
  });

  db.close();

  callback({ success: true });
}

async function getActionsTmp(folderId, callback) {
  const db = await connection();
  let data = await db.getAll("actionsTmp");

  if (data) {
    data = data.filter((element) => element.folderId === folderId);
  }

  db.close();

  callback({ success: !!data.length, data });
}

async function deleteActionsTmp(folderId, callback) {
  const db = await connection();
  let data = await db.getAll("actionsTmp");

  if (data) {
    data = data.filter((element) => element.folderId === folderId);
    const store = await db
      .transaction("actionsTmp", "readwrite")
      .objectStore("actionsTmp");

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      await store.delete(element.idLocal);
    }
  }

  db.close();

  callback({ success: true });
}

/* END - ACTIONS TMP */

export {
  getFolders,
  getFolder,
  insertFolder,
  deleteFolder,
  updateFolder,
  getAll,
  insertAll,
  getAllFolder,
  insertAllFolder,
  insertActionsTmp,
  getActionsTmp,
  deleteActionsTmp,
};
