import React from "react";
import { FormRadio, Modal, Button, FormCheckbox } from "ui-kit-ck-consultant";

import AuthContext from "../../../../context/AuthContext";

export default class ModalReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      idxExpertises: [],
    };
  }

  static contextType = AuthContext;

  render() {
    const { type, idxExpertises } = this.state;
    const { dataAdministrative } = this.context;
    const expertises = this.props.dataExpertise;

    const typeMission = parseInt(
      dataAdministrative.dataAdministrativeInformation.mission.typeMission
    );

    // 9 = panne mécanique
    const isPM = [9].includes(typeMission);
    const isPJ = [1, 7, 8].includes(typeMission);
    const isRC = [2].includes(typeMission);

    return (
      <Modal
        closeMessage="Êtes-vous sûr de vouloir fermer cette fenêtre ?"
        title="Nouveau rapport"
        show={this.props.show}
        onClose={this.props.onClose}
        isMove
      >
        <h4 className="mb-5 mt-10">Type de rapport</h4>
        {isRC && (
          <>
            <FormRadio
              text="Rapport d'information Rc"
              value={"informationRc"}
              className="mb-10"
              checked={type === "informationRc"}
              onChange={({ target }) => this.setState({ type: target.value })}
              defaultChecked
            />
            <FormRadio
              text="Rapport final Rc"
              value={"finalRc"}
              className="mb-10"
              checked={type === "finalRc"}
              onChange={({ target }) => this.setState({ type: target.value })}
            />
          </>
        )}

        {isPM ? (
          <>
            <FormRadio
              text={`Rapport final PM`}
              value={"final"}
              className="mb-10"
              checked={type === "final"}
              onChange={({ target }) => this.setState({ type: target.value })}
            />
          </>
        ) : null}

        {isPJ && (
          <>
            {this.props.company !== "macif" ? (
              <FormRadio
                text="Rapport d'information"
                value={"information"}
                className="mb-10"
                checked={type === "information"}
                onChange={({ target }) => this.setState({ type: target.value })}
                defaultChecked
              />
            ) : null}
            <FormRadio
              text={`Rapport final${
                this.props.company === "macif" ? " macif" : ""
              }`}
              value={"final"}
              className="mb-10"
              checked={type === "final"}
              onChange={({ target }) => this.setState({ type: target.value })}
            />
            {this.props.isSimplified ? (
              <FormRadio
                text={`Rapport simplifié ${this.props.company}`}
                value={"simplified"}
                className="mb-10"
                checked={type === "simplified"}
                onChange={({ target }) => this.setState({ type: target.value })}
              />
            ) : null}
          </>
        )}

        <h4 className="mb-5 mt-10">Expertises associées</h4>
        {expertises.map((expertise, idx) => (
          <FormCheckbox
            key={idx}
            text={`${expertise.name} n°${idx + 1}`}
            checked={idxExpertises.includes(idx)}
            onChange={() => {
              let idxExpertises = this.state.idxExpertises;

              if (idxExpertises.includes(idx)) {
                idxExpertises.splice(idxExpertises.indexOf(idx), 1);
              } else {
                idxExpertises.push(idx);
              }

              this.setState({ expertises: idxExpertises });
            }}
          />
        ))}
        <div className="ml-auto">
          <Button
            className=" ml-auto"
            text="Valider"
            onClick={() => {
              if (type && idxExpertises.length) {
                this.props.addReport(type, idxExpertises);
              } else {
                window.alert("Veuillez remplir tous les champs");
              }
            }}
          ></Button>
        </div>
      </Modal>
    );
  }
}
