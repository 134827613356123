import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { FormInput, FormSelect, ButtonIcon } from "ui-kit-ck-consultant";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CustomCard from "../../general/CustomCard";
import ModalPlaceOfExpertise from "../../general/ModalPlaceOfExpertise";

import AuthContext from "../../../../context/AuthContext";


export default class Informations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModal: false,
    };
    this.displayName = "Informations";
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = value;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("informations", tmpData);
    } else {
      this.context.updateDataReport("informations", tmpData);
    }
  };

  getData = () => {
    const { data } = this.props;

    return (
      <Row>
        <Col xs={12} md={3}>
          <FormInput
            className="mt-10"
            type="date"
            title="Date d'expertise"
            name="date"
            value={data.date}
            onChange={this.handleInputChange}
          />
        </Col>
        <Col xs={12} md={3}>
          <FormInput
            className="mt-10"
            type="time"
            title="Heure de début"
            name="hour"
            value={data.hour}
            onChange={this.handleInputChange}
          />
        </Col>
        <Col xs={12} md={3}>
          <FormSelect
            className="mt-10"
            title="Type d'expertise"
            name="type"
            value={data.type}
            init="Veuillez sélectionner un type d'expertise"
            ignore={true}
            options={[
              { text: "Unilatérale", value: "Unilatérale" },
              { text: "Contradictoire", value: "Contradictoire" },
              { text: "Judiciaire", value: "Judiciaire" },
            ]}
            onChange={this.handleInputChange}
          />
        </Col>
        <Col xs={12} md={3} lg={3} className="mt-10">
          <span style={{ fontSize: "14px" }}>
            <b>Lieu d'expertise</b>
          </span>
          <div
            className="bg-gray-light p-10 mt-10"
            style={{ borderRadius: "6px" }}
            title="Lieu d'expertise"
          >
            <Row end="xs">
              <div className="d-flex mr-10">
                <ButtonIcon
                  className="ml-auto p-0"
                  small
                  onClick={() => {
                    this.setState({ isModal: true });
                  }}
                >
                  <FontAwesomeIcon className="black" icon={faPen} />
                </ButtonIcon>
              </div>
            </Row>
            <p className="m-0 mb-5">
              <b>{data.placeOfExpertise.name}</b>
            </p>
            <p className="m-0 mb-5">{data.placeOfExpertise.address}</p>
            <p className="m-0 mb-5">{data.placeOfExpertise.address2}</p>
            <p className="m-0 mb-5">{data.placeOfExpertise.zipCode}</p>
            <p className="m-0 mb-5">{data.placeOfExpertise.city}</p>
          </div>
        </Col>
        <ModalPlaceOfExpertise
          show={this.state.isModal}
          data={this.props.data.placeOfExpertise}
          updateData={(data) => {
            let tmpData = this.props.data;
            tmpData.placeOfExpertise = data;

            this.setState({ isModal: false }, () => {
              this.setData(tmpData);
            });
          }}
          onClose={() => this.setState({ isModal: false })}
        />
      </Row>
    );
  };

  render() {
    if (this.context.isReport) {
      return this.getData();
    }

    return (
      <CustomCard title="Informations" name={this.displayName}>
        {this.getData()}
      </CustomCard>
    );
  }
}
