import React from "react";
import { FormInput, Switch } from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";

import Lexical from "../../../../lexical/Lexical";

import AuthContext from "../../../../context/AuthContext";

export default class Imputabilities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: "",
    };
    this.displayName = "Imputabilities";
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState({
      editorState: this.props.data.circumstances || "",
    });
  }

  handleInputChange = (e) => {
    const { value, name } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = value;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    this.context.updateDataReport("imputabilities", tmpData);
  };

  getSwitch = (text, name, textInput = null, textDate = null) => (
    <>
      <div className="d-flex mb-20 mt-auto">
        <p className="mt-auto mb-auto mr-10 nowrap">{text}</p>
        <Switch
          className="mt-auto mb-auto"
          value1="Non"
          value2="Oui"
          color1="#f2711c"
          color2="#026fc2"
          idxActive={
            this.props.data.hasOwnProperty(`switch_${name}`)
              ? this.props.data[`switch_${name}`]
              : 0
          }
          onChange={(type) => {
            let tmpData = this.props.data;
            tmpData[`switch_${name}`] = type;
            this.setData(tmpData);
          }}
        />
        {textInput &&
        this.props.data.hasOwnProperty(`switch_${name}`) &&
        this.props.data[`switch_${name}`] ? (
          <div className="d-flex">
            <FormInput
              className="mt-auto mb-auto ml-10"
              placeholder={textInput}
              name={`text_${name}`}
              value={this.props.data[`text_${name}`] || ""}
              onChange={this.handleInputChange}
            />
            <span className="nowrap mt-auto mb-auto ml-10">
              € HT /{" "}
              {(parseFloat(this.props.data[`text_${name}`] || 0) * 1.2).toFixed(
                2
              )}{" "}
              € TTC
            </span>
          </div>
        ) : null}
      </div>
      <div className="d-flex mb-20 mt-auto">
        {textDate &&
        this.props.data.hasOwnProperty(`switch_${name}`) &&
        this.props.data[`switch_${name}`] ? (
          <div className="d-flex">
            <FormInput
              type="date"
              className="mt-auto mb-auto ml-10"
              title={textDate}
              name={`text2_${name}`}
              value={this.props.data[`text2_${name}`] || ""}
              onChange={this.handleInputChange}
            />
          </div>
        ) : null}
      </div>
    </>
  );

  getData = () => {
    return (
      <>
        <p>Circonstance de la panne : </p>
        <Lexical
          value={this.state.editorState}
          onChange={(editorState) =>
            this.setState({ editorState }, () => {
              let tmpData = this.props.data;
              tmpData.circumstances = editorState;
              this.setData(tmpData);
            })
          }
        />

        {this.getSwitch("Panne fortuite", "failure")}
        {this.getSwitch(
          "Panne antérieure à la prise d’effet du contrat",
          "failureBefore"
        )}
        {this.getSwitch(
          "Panne liée au kilométrage du véhicule (usure normale)",
          "failureMileage"
        )}
        {this.getSwitch(
          "Panne consécutive à un défaut d’entretien",
          "failureMaintenance"
        )}
        {this.getSwitch("Responsabilité d’un tiers engagée", "responsability")}
        {this.getSwitch(
          "Le conducteur aurait-t-il dû être alerté par des signes avant-coureurs",
          "alert"
        )}
        {this.getSwitch(
          "Y a-t-il eu aggravation des dommages du fait de l’utilisation du véhicule",
          "worsening"
        )}
        {this.getSwitch(
          "La panne est-elle récurrente sur ce type de véhicule",
          "failureRecurring"
        )}
        {this.getSwitch(
          "Une participation du constructeur est-elle envisageable",
          "participation"
        )}
      </>
    );
  };

  render() {
    return (
      <CustomCard title="Imputabilités" name={this.displayName}>
        {this.getData()}
      </CustomCard>
    );
  }
}
