import React from "react";
import { FormInput, Switch } from "ui-kit-ck-consultant";
import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class ExpertiseConditionsPM extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "ExpertiseConditionsPM";
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = value;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("expertiseConditionsPM", tmpData);
    } else {
      this.context.updateDataReport("expertiseConditionsPM", tmpData);
    }
  };

  getSwitch = (text, name, textInput = null, textDate = null) => (
    <>
      <div className="d-flex mb-20 mt-auto">
        <p className="mt-auto mb-auto mr-10 nowrap">{text}</p>
        <Switch
          className="mt-auto mb-auto"
          value1="Non"
          value2="Oui"
          color1="#f2711c"
          color2="#026fc2"
          idxActive={
            this.props.data.hasOwnProperty(`switch_${name}`)
              ? this.props.data[`switch_${name}`]
              : 0
          }
          onChange={(type) => {
            let tmpData = this.props.data;
            tmpData[`switch_${name}`] = type;
            this.setData(tmpData);
          }}
        />
        {textInput &&
        this.props.data.hasOwnProperty(`switch_${name}`) &&
        this.props.data[`switch_${name}`] ? (
          <div className="d-flex">
            <FormInput
              className="mt-auto mb-auto ml-10"
              placeholder={textInput}
              name={`text_${name}`}
              value={this.props.data[`text_${name}`] || ""}
              onChange={this.handleInputChange}
            />
            <span className="nowrap mt-auto mb-auto ml-10">
              € HT /{" "}
              {(parseFloat(this.props.data[`text_${name}`] || 0) * 1.2).toFixed(
                2
              )}{" "}
              € TTC
            </span>
          </div>
        ) : null}
      </div>
      <div className="d-flex mb-20 mt-auto">
        {textDate &&
        this.props.data.hasOwnProperty(`switch_${name}`) &&
        this.props.data[`switch_${name}`] ? (
          <div className="d-flex">
            <FormInput
              type="date"
              className="mt-auto mb-auto ml-10"
              title={textDate}
              name={`text2_${name}`}
              value={this.props.data[`text2_${name}`] || ""}
              onChange={this.handleInputChange}
            />
          </div>
        ) : null}
      </div>
    </>
  );

  getData = () => {
    return (
      <>
        {this.getSwitch("Véhicule présenté", "vehiclePresented")}
        {this.getSwitch("Véhicule roulant", "vehicleRunning")}
        {this.getSwitch("Véhicule réparé", "vehicleRepaired")}
        {this.getSwitch("Véhicule démonté", "vehicleDismantled")}
        {this.getSwitch("Examen sur pièces", "vehicleParts")}
      </>
    );
  };

  render() {
    if (this.context.isReport) {
      return this.getData();
    }

    return (
      <CustomCard title="Conditions d'examen" name={this.displayName}>
        {this.getData()}
      </CustomCard>
    );
  }
}
